/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyBMlHAuGm14uP85mOWB3CYVXaaBDatRAF8",
  "appId": "1:674075098668:web:c38a58fe2f0ec984b11b89",
  "authDomain": "schooldog-i-wayne-ga.firebaseapp.com",
  "measurementId": "G-PVD1HHVHFX",
  "messagingSenderId": "674075098668",
  "project": "schooldog-i-wayne-ga",
  "projectId": "schooldog-i-wayne-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-wayne-ga.appspot.com"
}
